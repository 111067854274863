<template>
  <form @submit.prevent="onFormSubmit">
    <slot :form="form"></slot>
  </form>
</template>

<script>
import FormValidationError from '@/errors/FormValidationError'
import clone from 'rfdc'
import Vue from 'vue'
import { ZodError } from 'zod'

export default {
  props: {
    onSubmit: {
      type: Function,
      required: false,
      default: () => {}
    },
    onSubmitSuccess: {
      type: Function,
      required: false,
      default: () => {}
    },
    onSubmitError: {
      type: Function,
      required: false,
      default: () => {}
    },
    onValidationError: {
      type: Function,
      required: false,
      default: () => {}
    },
    initialValue: {
      type: Object,
      required: false,
      default: () => ({})
    },
    processForm: {
      type: Function,
      required: false,
      default: (form) => {
        return form
      }
    },
    zodSchemaValidator: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        values: clone()(this.initialValue),
        errors: {},
        types: {}
      }
    }
  },
  methods: {
    async onFormSubmit() {
      const preprocessedForm = this.processForm({ ...this.form.values })
      try {
        if (Object.keys(this.zodSchemaValidator).length > 0) {
          this.zodSchemaValidator.parse(preprocessedForm)
        }
        const returnedObject = await this.onSubmit(preprocessedForm)
        return this.onSubmitSuccess(returnedObject)
      } catch (err) {
        if (err instanceof FormValidationError) {
          this.form.types = {}
          this.form.errors = {}

          const errorDetail = err.report?.detail || []
          if (Array.isArray(errorDetail)) {
            errorDetail.forEach((error) => {
              const field = error.loc[error.loc.length - 1]
              Vue.set(this.form.types, field, 'is-danger')
              Vue.set(this.form.errors, field, error.msg)
            })
          }
          this.onValidationError({ err, formErrors: this.form.errors })
        } else if (err instanceof ZodError) {
          err.issues.map((error) => {
            Vue.set(this.form.types, error.path[0], 'is-danger')
            Vue.set(this.form.errors, error.path[0], this.$t('form.fieldRequired'))
          })
        } else {
          this.onSubmitError(err)
        }
      }
    }
  }
}
</script>
